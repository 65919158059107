import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SalesOrdersRoutingModule } from './sales-orders-routing.module';
import { SalesOrdersOrganismComponent } from './sales-orders-organism.component';
import { RouterModule } from '@angular/router';
import { MatSelectFilterModule } from 'mat-select-filter';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "../../../material.module";


@NgModule({
  declarations: [SalesOrdersOrganismComponent],
  imports: [
    CommonModule,
    SalesOrdersRoutingModule,
    MatSelectFilterModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule, 
    ReactiveFormsModule,
    RouterModule,
    MaterialModule
  ]
})
export class SalesOrdersModule { }
