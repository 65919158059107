import { Component, OnInit , Inject } from '@angular/core';
import { StockService } from '../../../../services/stock.service';
import { FormDataConstructorService } from '../../../../services/form-data-constructor.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-stock-form-organism',
  templateUrl: './stock-form-organism.component.html',
  styleUrls: ['./stock-form-organism.component.scss']
})
export class StockFormOrganismComponent implements OnInit {
  formData: any;
  addFlag: any = true;
  stockId: string;
  isLoading = true
  constructor(private stockService: StockService, private snack: MatSnackBar,
              private formService: FormDataConstructorService, private route: ActivatedRoute,
              private router:Router,private dialogRef: MatDialogRef<StockFormOrganismComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { 
                this.stockService.getStockForm().subscribe((res: any) => {
                  let metaData  = res.metaData;
                  
                    if ( data  && data.materialCode ) {
                      const materialCode = data.materialCode;
                      const locationCode = data.locationCode;
                      this.addFlag = false;
                      let processedMetaData = [];
                      this.stockService.getStocksByMaterialAndLocationCodes(materialCode, locationCode).subscribe((data: any) => {
                        console.log("to check:" +data)
                        if (data) {
                          this.stockId = data.id;
                          this.stockService.getStockAttributesByMaterialAndLocation(materialCode, locationCode).subscribe((attributeData: any) => {
                            data.dynamicAttrs = attributeData.attributes;
                            const processedAttrs = this.processAttrData(data);
                            processedMetaData = this.processMetaData(metaData, processedAttrs);
                            this.formData = this.formService.formConstructor(processedMetaData);
                          },
                          (error) => {
                            this.isLoading = false;
                          },
                          () => {
                            this.isLoading = false;
                          }
                        );
                        }
                      });
                    } else {
                      this.addFlag = true;
                      this.stockId = null;
                      metaData = metaData.filter(each => each.identifier !== 'createdTime' && each.identifier !== 'modifiedTime');
                      this.formData = this.formService.formConstructor(metaData);
                      this.isLoading = false
                    }
                  
                });
              }

  ngOnInit(): void {
  }
  processAttrData(data) {
    data.dynamicAttrs.map((each) => {
      const key = each.attributeName;
      const val = each.attributeValue;
      data[key] = val;
    });
    // delete data.dynamicAttrs;
    return data;
  }
   
   // Map metaData with formData
   processMetaData(metaData: any[], data: any) {
    const processedMetaData = [...metaData];
  
    // Map values from data to metaData
    processedMetaData.forEach((eachMetaData) => {
      const key = eachMetaData.identifier;
      if (data[key] !== undefined) {
        eachMetaData.value = data[key];
      }
      eachMetaData.isReadOnly = ["createdTime", "modifiedTime"].includes(key);
    });
  
    return processedMetaData;
  }

  // processMetaData(metaData, data) {
  //   const processedMetaData = [];
  //   Object.keys(data).map((singleKey) => {
  //     metaData.map((eachMetaData) => {
  //       if (eachMetaData && singleKey === eachMetaData.identifier) {
  //         if(eachMetaData.dynamic) {
  //           data && data.dynamicAttrs.map((dynamicAttr) => {
  //             if(singleKey === dynamicAttr.attributeName) {
  //               eachMetaData['id'] = dynamicAttr.id;
  //             }
  //           })
  //         }
  //         eachMetaData.value = data[singleKey];
  //         if ((singleKey  === 'createdTime' || singleKey === 'modifiedTime')) {
  //           eachMetaData.isReadOnly = true;
  //           processedMetaData.push(eachMetaData);
  //         } else {
  //           eachMetaData.isReadOnly = false;
  //           processedMetaData.push(eachMetaData);
  //         }
  //       }
  //     });
  //   });
  //   return processedMetaData;
  // }

  submitForm(formValues) {
    this.stockService.saveStock(formValues, this.formData, this.addFlag, this.stockId).subscribe((response)=> {
      const message = this.addFlag ? 'Added successfully' : 'changes saved successfully';
      this.snack.open(message, 'close', {
        duration: 4000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      this.dialogRef.close(); 
    });
}
closeModal(){
 this.dialogRef.close(); 
}
}
