import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { materialColumns } from '../../../../properties/tableConfig';
import { MaterialsService } from '../../../../services/materials.service';
import { MatTableDataSource } from '@angular/material/table';
import { CommonUtilityService } from '../../../../services/common-utility-service';
import { SearchFilterService } from 'src/app/services/search-filter.service';
import { BulkUploadServiceService } from 'src/app/services/bulk-upload-service.service';
import { Subscription } from 'rxjs';
import { SyncfusionService } from 'src/app/services/syncfusion.service';
import { AppSettingService } from 'src/app/services/app-setting.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MaterialFormOrganismComponent } from 'src/app/shared/organisms/material-management/material-form-organism/material-form-organism.component';


@Component({
  selector: 'app-material-table-molecule',
  templateUrl: './material-table-molecule.component.html',
  styleUrls: ['./material-table-molecule.component.scss']
})
export class MaterialTableMoleculeComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  ELEMENT_DATA: any[];
  dataSource = new MatTableDataSource<any>();
  displayedColumns = materialColumns;
  add = false;
  update = false;
  searchField;
  nextLink:string;
  nextMaterialList$ = new Subscription();
  page: string

  clearSearchField() {
    this.searchField = '';
    this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
  }

  constructor(private service: MaterialsService,
    private utilService: CommonUtilityService,
    private searchService: SearchFilterService,
    private syncfusion: SyncfusionService,
    private settingService: AppSettingService,
    private snack: MatSnackBar,
    private bulkService: BulkUploadServiceService,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.service.getAllMaterials().subscribe((res: any) => {
      this.ELEMENT_DATA = res.materials;
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
	  if(res) {
        this.page=res?._links?.next?.href?.split("?")[1];
       }
    });
    const accessRules = JSON.parse(this.utilService.getCookie('accessRules'))?.accessRules;
    accessRules?.map(each => {
      if (each.entity === 'material') {
        this.add = each.create;
        this.update = each.update;
      }
    });
  }
  openMaterialForm(code?: string) {
    const dialogRef = this.dialog.open(MaterialFormOrganismComponent, {
      width: '400px',
      // panelClass: 'custom-dialog',
      position: { right: '0px' , top: '64px'} ,
      data: { code } // Pass the material code if available
    });
    dialogRef.afterOpened().subscribe(() => {
      const dialogContainer = document.querySelector(
        ".cdk-overlay-pane"
      ) as HTMLElement;
      if (dialogContainer) {
        dialogContainer.style.position = "fixed";
        dialogContainer.style.top = "64px";
        dialogContainer.style.right = "0px";
        dialogContainer.style.transform = "none"; // Remove default centering
        dialogContainer.style.margin = "0";
        dialogContainer.style.height = '100vh'
      }
    }); 
  
    dialogRef.afterClosed().subscribe(() => {
      // Refresh data or handle any post-dialog logic if needed
      this.ngOnInit(); // Refresh the table data
    });
  }
  public doFilter = (value: string) => {
    //this.dataSource.filter = value.trim().toLocaleLowerCase();
    if(!value) {
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }

    else this.searchService.searchFilter('material', value, 'code').subscribe((res: any) => {
      this.dataSource = new MatTableDataSource<any>(res.materials);
    })
  }

  downLoadReport() {
    // this.bulkService.downLoadReport('material');
    if(this.settingService.isRemoteGridMode()){
      const data = {
        downloadEntity: true
      };
      const reportName = 'Material_Master';
      this.syncfusion.exportDataToExcel(data,reportName).subscribe((response) => {
        if (response.status === 307) {
          this.bulkService.downLoadReport('material')
        } else {
          this.snack.open("Download triggered Successfully", "close", {
            duration: 4000,
            horizontalPosition: "center",
            verticalPosition: "top",
          });
        }
      }, (error) => {
        console.error('Error in Excel Export:', error);
      });

    }else{
      this.bulkService.downLoadReport('material');
    }  
  }

    getNextMaterial() {
    if(this.page) {
      this.nextMaterialList$ = this.service.getNextMaterial(this.page).subscribe((res: any) => {
        this.page = res?._links?.next?.href?.split("?")[1];
        this.ELEMENT_DATA.push(... res.materials);
        this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.paginator.nextPage();
      });
    }
  }


    pageChanged(event) {
    if (this.page) {
      if (!(event.previousPageIndex > event.pageIndex)) {
        this.getNextMaterial();
      }
    }
  }

  ngOnDestroy() {
    this.nextMaterialList$.unsubscribe();
  }
}


