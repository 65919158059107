<div class="filter-body" *ngIf="isLoading">
    <ng-container *ngFor="let number of [0, 1, 2, 3, 4]">
      <div class="holder-card mat-elevation-z4">
        <div style="font-weight: lighter; width: 100%; height: 2em" class="flowsmart-placeholder"></div>
        <div style="font-weight: lighter; width: 100%; height: 2em" class="flowsmart-placeholder"></div>
        <div style="font-weight: lighter; width: 100%; height: 2em" class="flowsmart-placeholder"></div>
        <div style="font-weight: lighter; width: 100%; height: 1em" class="flowsmart-placeholder"></div>
      </div>
    </ng-container>
    </div>

<mat-toolbar>
    Share Of Business Form
    <div class="pt-2 pl-2 close_icon">
        <div class="modal_close"  (click)="onCancel()">
            <span class="material-icons">
               +
            </span>
        </div>
    </div>
</mat-toolbar>

<app-dynamic-form-molecule *ngIf="formData" [formData]='formData' [addFlag]='addFlag'  (submitForm)="submitForm($event)"></app-dynamic-form-molecule>
