import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectivesModule } from './directives/directives.module';
import { MoleculesModule } from './molecules/molecules.module';
import { OrganismsModule } from './organisms/organisms.module';
import { PipesModule } from './pipes/pipes.module';
import { SalesOrdersModule } from './organisms/sales-orders-organism/sales-orders.module';

@NgModule({
  imports: [
    CommonModule,
    SalesOrdersModule,
  ],
  exports: [
    CommonModule,
    OrganismsModule,
    MoleculesModule,
    PipesModule,
    DirectivesModule
  ],
  declarations: []
})
export class SharedModule { }
