import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MaterialsService } from '../../../../services/materials.service';
import { FormDataConstructorService } from '../../../../services/form-data-constructor.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-material-form-organism',
  templateUrl: './material-form-organism.component.html',
  styleUrls: ['./material-form-organism.component.scss'],
  animations: [
    trigger('modalAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-30px)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ opacity: 0, transform: 'translateY(-30px)' })),
      ]),
    ]),
  ],
})
export class MaterialFormOrganismComponent implements OnInit {
  formData: any;
  addFlag: boolean = true;
  materialId: string;
  isLoading = true
  constructor(
    public dialogRef: MatDialogRef<MaterialFormOrganismComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private materialService: MaterialsService,
    private snack: MatSnackBar,
    private formService: FormDataConstructorService
  ) {
    this.materialService.getMaterialForm().subscribe((res: any) => {
      const metaData = res.metaData;
      if (data.code) {
        this.addFlag = false;
        this.materialService.getMaterialByCode(data.code).subscribe((data: any) => {
          if (data) {
            this.materialId = data.id;
            const attrAPI = data.code;
            this.materialService.getMaterialAttributes(attrAPI).subscribe((attributeData: any) => {
              data.dynamicAttrs = attributeData.attributes;
              const processedAttrs = this.processAttrData(data);
              const processedMetaData = this.processMetaData(metaData, processedAttrs);
              this.formData = this.formService.formConstructor(processedMetaData);
            },
            (error) => {
              this.isLoading = false;
            },
            () => {
              this.isLoading = false;
            }
          );
          }
        });
      } else {
        this.addFlag = true;
        this.materialId = null;
        const filteredMetaData = metaData.filter(each => each.identifier !== 'createdTime' && each.identifier !== 'modifiedTime');
        this.formData = this.formService.formConstructor(filteredMetaData);
        this.isLoading = false
      }
    });
  }

  ngOnInit(): void {}

  processAttrData(data) {
    data.dynamicAttrs.forEach((each) => {
      const key = each.attributeName;
      const val = each.attributeValue;
      data[key] = val;
    });
    return data;
  }
   // Map metaData with formData
  processMetaData(metaData: any[], data: any) {
    const processedMetaData = [...metaData];
  
    // Map values from data to metaData
    processedMetaData.forEach((eachMetaData) => {
      const key = eachMetaData.identifier;
      if (data[key] !== undefined) {
        eachMetaData.value = data[key];
      }
      eachMetaData.isReadOnly = ["createdTime", "modifiedTime"].includes(key);
    });
  
    return processedMetaData;
  }
  // processMetaData(metaData, data) {
  //   const processedMetaData = [];
  //   Object.keys(data).forEach((singleKey) => {
  //     metaData.forEach((eachMetaData) => {
  //       if (eachMetaData && singleKey === eachMetaData.identifier) {
  //         eachMetaData.value = data[singleKey];
  //         eachMetaData.isReadOnly = (singleKey === 'createdTime' || singleKey === 'modifiedTime');
  //         processedMetaData.push(eachMetaData);
  //       }
  //     });
  //   });
  //   return processedMetaData;
  // }

  submitForm(formValues) {
    this.materialService.saveMaterial(formValues, this.formData, this.addFlag, this.materialId).subscribe(() => {
      const message = this.addFlag ? 'Added successfully' : 'Changes saved successfully';
      this.snack.open(message, 'close', {
        duration: 4000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      this.dialogRef.close();
    });
  }
}
