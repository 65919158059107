import { Routes } from "@angular/router";
import { AuthGuard } from "./services/auth-guard.service";
import { ChangePwdOrganismsComponent } from "./shared/organisms/change-pwd-organisms/change-pwd-organisms.component";
import { LoginFormComponent } from "./shared/organisms/login-form/login-form.component";
import { HomePageTemplateComponent } from "./templates/home-page-template/home-page-template.component";
import { MaterialsTemplateComponent } from "./templates/materials-template/materials-template.component";
import { LocationsTemplateComponent } from "./templates/locations-template/locations-template.component";
// tslint:disable-next-line: max-line-length
import { LocationsFormOrganismComponent } from "./shared/organisms/locations-management/locations-form-organism/locations-form-organism.component";
import { MyNavTemplateComponent } from "./templates/my-nav-template/my-nav-template.component";
import { LoginMoleculeComponent } from "./shared/molecules/login-molecule/login-molecule.component";
import { RegisterMoleculeComponent } from "./shared/molecules/register-molecule/register-molecule.component";
// tslint:disable-next-line: max-line-length
import { MaterialFormOrganismComponent } from "./shared/organisms/material-management/material-form-organism/material-form-organism.component";
// tslint:disable-next-line: max-line-length
import { ParameterManagementOrganismComponent } from "./shared/organisms/parameters-management/parameter-management-material-organism/parameter-management-material-organism.component";
// tslint:disable-next-line: max-line-length
import { ParameterManagementFormOrganismComponent } from "./shared/organisms/parameters-management/parameter-management-form-organism/parameter-management-form-organism.component";
// tslint:disable-next-line: max-line-length
import { ParameterManagementLocationsOrganismComponent } from "./shared/organisms/parameters-management/parameter-management-locations-organism/parameter-management-locations-organism.component";
import { ParameterManagementStockCriteriaOrganismComponent } from "./shared/organisms/parameters-management/parameter-management-stock-criteria-organism/parameter-management-stock-criteria-organism.component";
import { ParameterManagementShareOfBusinessOrganismComponent } from "./shared/organisms/parameters-management/parameter-management-share-of-business-organism/parameter-management-share-of-business-organism.component";
import { ReportsOrganismComponent } from "./shared/organisms/reports/reports-organism/reports-organism.component";
import { StockCriteriaComponent } from "./shared/organisms/stock-management/stock-criteria/stock-criteria.component";
import { StockFormOrganismComponent } from "./shared/organisms/stock-management/stock-form-organism/stock-form-organism.component";
// tslint:disable-next-line: max-line-length
import { ShareOfBusinessOrganismComponent } from "./shared/organisms/shareofbusiness-management/shareofbusiness-organism/shareofbusiness-organism.component";
import { DynamicNormOrganismComponent } from "./shared/organisms/dynamicnorm-management/dynamicnorm-organism/dynamicnorm-organism.component";
// tslint:disable-next-line: max-line-length
import { ShareOfBusinessFormOrganismComponent } from "./shared/organisms/shareofbusiness-management/shareofbusiness-form-organism/shareofbusiness-form-organism.component";
import { UserOrganismComponent } from "./shared/organisms/user-management/user-organism/user-organism.component";
import { UserFormOrganismComponent } from "./shared/organisms/user-management/user-form-organism/user-form-organism.component";
import { BulkUploadOrganismComponent } from "./shared/organisms/bulk-upload-organism/bulk-upload-organism.component";
import { SsoCallbackComponent } from "./shared/organisms/sso-callback/sso-callback.component";
import { ForgetPasswdComponent } from "./shared/molecules/forget-passwd/forget-passwd.component";
import { PlanningStockTableMoleculeComponent } from "./shared/molecules/planning-stock-table-molecule/planning-stock-table-molecule.component";
import { InnerPlanningstockComponent } from "./shared/molecules/inner-planningstock/inner-planningstock.component";
import { SyncFusionOrganismsComponent } from "./shared/organisms/sync-fusion-organisms/sync-fusion-organisms.component";
import { BufferTrendMoleculesComponent } from "./shared/molecules/buffer-trend-molecules/buffer-trend-molecules.component";
import { ChartjsOrganismComponent } from "./shared/organisms/chatjs-reports/chartjs-organism/chatjs-organism.component";
import { UploadFilesComponent } from "./shared/organisms/upload-files/upload-files.component";
import { HistoricalReportsComponent } from "./shared/organisms/historical-reports/historical-reports.component";
import { ScmManagementOrganismComponent } from "./shared/organisms/scm-management-organisms/scm-management-organism/scm-management-organism.component";
import { ScmManagementFormOrganismComponent } from "./shared/organisms/scm-management-organisms/scm-management-form-organism/scm-management-form-organism.component";
import { SalesForecastComponent } from "./shared/organisms/sales-forecast/sales-forecast.component";
import { BusinessIntelligenceOrgansimComponent } from "./shared/organisms/business-intelligence-organsim/business-intelligence-organsim.component";
import { DynamicNormReviewOrganismComponent } from "./shared/organisms/dynamic-norm-review-organism/dynamic-norm-review-organism.component";
import { ProductsPerformanceAnalyzerComponent } from "./shared/organisms/products-performance-analyzer/products-performance-analyzer.component";
import { GridListMoleculeComponent } from "./shared/molecules/grid-list-molecule/grid-list-molecule.component";
import { DetailPageMoleculeComponent } from "./shared/molecules/detail-page-molecule/detail-page-molecule.component";
import { ReturnsAnalyzerOrganismsComponent } from "./shared/organisms/returns-analyzer-organisms/returns-analyzer-organisms.component";
import { TrendsAndReportsOrgansimComponent } from "./shared/organisms/trends-and-reports-organsim/trends-and-reports-organsim.component";
import { CachingMoleculeComponent } from "./shared/molecules/caching-molecule/caching-molecule.component";
import { InventoryHealthOrganismComponent } from "./shared/organisms/inventory-health-organism/inventory-health-organism.component";
import { ProductionIntentPlanningOrganismsComponent } from "./shared/organisms/production-intent-planning-organisms/production-intent-planning-organisms.component";
import { BrokenSetReportOrganismComponent } from "./shared/organisms/broken-set-report-organism/broken-set-report-organism.component";
import { RmProcurementReportOrganismComponent } from "./shared/organisms/rm-procurement-report-organism/rm-procurement-report-organism.component";
import { ProductionIntentReportOrganismComponent } from "./shared/organisms/production-intent-report-organism/production-intent-report-organism.component";
import { ForecastingTemplateOrganismComponent } from "./shared/organisms/forecasting-template-organism/forecasting-template-organism.component";
import { RawMaterialDemandOrganismsComponent } from "./shared/organisms/rm-demand-allocation-organisms/rm-demand-allocation-organisms.component";
import { PredictPromotionListingOrganismsComponent } from "./shared/organisms/predict-promotion-listing-organisms/predict-promotion-listing-organisms.component";
import { ProductsSalesAnalyzerComponent } from './shared/organisms/products-sales-analyzer/products-sales-analyzer.component';
import { WorkflowOrganismComponent } from './shared/organisms/workflow-organism/workflow-organism.component';
import { ChatbotComponent } from "./shared/organisms/chatbot/chatbot.component";
import { LandingPageGuard } from "./services/landingpage-guard.service";
import { InquiriesManagementOrganismComponent } from "./shared/organisms/inquiries-management-organism/inquiries-management-organism.component";
import { ForecastingGynovedaOrganismComponent } from "./shared/organisms/forecasting-gynoveda-organism/forecasting-gynoveda-organism.component";
import { DynamicCustomObjectOrganismComponent } from "./shared/organisms/dynamic-custom-object-organism/dynamic-custom-object-organism.component";
import { InventoryPivotOrganismComponent } from "./shared/organisms/inventory-pivot-organism/inventory-pivot-organism.component";
import { SizeRatioConverterOrganismComponent } from "./shared/organisms/size-ratio-converter-organism/size-ratio-converter-organism.component";
import { SalesOrdersOrganismComponent } from "./shared/organisms/sales-orders-organism/sales-orders-organism.component";
import { SupplierPerformanceOrganismComponent } from "./shared/organisms/supplier-performance-organism/supplier-performance-organism.component";
import { ReOrderingOrganismComponent } from "./shared/organisms/re-ordering-organism/re-ordering-organism.component";
import { ReconciliationOrganismComponent } from "./shared/organisms/reconciliation-organism/reconciliation-organism.component";
export const routes: Routes = [
  // {
  //     path: '',
  //     component: LoginFormComponent,
  // },
  {
    path: "login",
    component: LoginMoleculeComponent,
    title: "Login"
  },
  {
    path: "changepwd",
    component: ChangePwdOrganismsComponent,
    canActivate: [AuthGuard],
    title: "Change Password"
  },
  {
    path: "register",
    component: RegisterMoleculeComponent,
    title: "Register"
  },
  {
    path: "sso/callback/:id",
    component: SsoCallbackComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "grid",
    component: SyncFusionOrganismsComponent,
    canActivate: [AuthGuard],
    title: "Sync Fusion grid"
  },
  {
    path: "replenishment-plan",
    component: ProductionIntentPlanningOrganismsComponent,
    canActivate: [AuthGuard],
    title: "Replenishment Plan"
  },
  {
    path: "rm-demand",
    component: RawMaterialDemandOrganismsComponent,
    canActivate: [AuthGuard],
    title: "RM Demand"
  },
  {
    path: "broken-set",
    component: BrokenSetReportOrganismComponent,
    canActivate: [AuthGuard],
    title: "Product Broken Set"
  },
  {
    path: "rm-procurement",
    component: RmProcurementReportOrganismComponent,
    canActivate: [AuthGuard],
    title: "RM Procurement Plan"
  },
  {
    path: "production-plan",
    component: ProductionIntentReportOrganismComponent,
    canActivate: [AuthGuard],
    title: "Production Plan"
  },
  {
    path: "forecast",
    component: ForecastingTemplateOrganismComponent,
    canActivate: [AuthGuard],
    title: "Forecasting"
  },
  {
    path: "gynovedaforecast",
    component: ForecastingGynovedaOrganismComponent,
    canActivate: [AuthGuard],
    title: "Gynoveda Forecasting"
  },
  {
    path: "upload",
    component: UploadFilesComponent,
    canActivate: [AuthGuard],
    title: "Bulk Upload"
  },
  {
    path: "bufferHistory",
    component: TrendsAndReportsOrgansimComponent,
    canActivate: [AuthGuard],
    title: "Buffer Penetration History"
  },
  {
    path: "availability-analyser",
    component: InventoryHealthOrganismComponent,
    canActivate: [AuthGuard],
    title: "Availability Insights"
  },
  {
    path: "sales-analyser",
    component: BusinessIntelligenceOrgansimComponent,
    canActivate: [AuthGuard],
    title: "Sales Insights"
  },
  {
    path: "products/listing",
    component: GridListMoleculeComponent,
    canActivate: [AuthGuard],
    title: "Products Performance Listing"
  },
  {
    path: "products-analyser",
    component: ProductsSalesAnalyzerComponent,
    canActivate: [AuthGuard],
    title: "Products Insights"
  },
  {
    path: "dynamicnorm-review",
    component: DynamicNormReviewOrganismComponent,
    canActivate: [AuthGuard],
    title: "Demand Calibration Review"
  },
  {
    path: "chartjs",
    component: ChartjsOrganismComponent,
    canActivate: [AuthGuard],
    title: "Chart JS"
  },
  {
    path: 'ask-hawk',
    component: ChatbotComponent,
    canActivate: [AuthGuard],
    title: "Hawk - Data Sharpest Insight, Delivered Instantly"
  },
  {
    path: '',
    canActivate: [AuthGuard, LandingPageGuard],
  },
  {
    path: "product/listing",
    component: DetailPageMoleculeComponent,
    canActivate: [AuthGuard],
    title: "Product Details"
  },
  {
    path: "product/promotions/predict",
    component: PredictPromotionListingOrganismsComponent,
    canActivate: [AuthGuard],
    title: "Promotion Planner"
  },
  {
    path: "return-analyzer",
    component: ReturnsAnalyzerOrganismsComponent,
    canActivate: [AuthGuard],
    title: "Return Insights"
  },
  {
    path: "WorkOrders",
    component: ScmManagementOrganismComponent,
    canActivate: [AuthGuard],
    title: "WorkOrder Management"
  },
  {
    path: "SCMForm",
    component: ScmManagementFormOrganismComponent,
    canActivate: [AuthGuard],
    title: "WorkOrder Detail"
  },
  {
    path: "passwordReset",
    component: ForgetPasswdComponent,
    title: "Password Reset"
  },
  {
    path: "material",
    component: MaterialsTemplateComponent,
    canActivate: [AuthGuard],
    title: "Material Master Listing"
  },
  {
    path: "planningStock",
    component: PlanningStockTableMoleculeComponent,
    canActivate: [AuthGuard],
    title: "Supplier Stock Planning"
  },
  {
    path: "iplanningStock",
    component: InnerPlanningstockComponent,
    canActivate: [AuthGuard],
    title: "Supplier Stock Planning"
  },
  {
    path: "materialForm",
    component: MaterialFormOrganismComponent,
    canActivate: [AuthGuard],
    title: "Product Edit Page"
  },
  {
    path: "report",
    component: HistoricalReportsComponent,
    canActivate: [AuthGuard],
    title: "Historical Detailed Reports"
  },
  {
    path: "locations",
    component: LocationsTemplateComponent,
    canActivate: [AuthGuard],
    title: "Location Master"
  },
  {
    path: "locationsForm",
    component: LocationsFormOrganismComponent,
    canActivate: [AuthGuard],
    title: "Location Edit Page"
  },
  {
    path: "salesforecast",
    component: SalesForecastComponent,
    canActivate: [AuthGuard],
    title: "Demand Sensing"
  },
  {
    path: "parameter-mgt-material",
    component: ParameterManagementOrganismComponent,
    canActivate: [AuthGuard],
    title: "Parameter Management for Materials"
  },
  {
    path: "parameter-mgt-locations",
    component: ParameterManagementLocationsOrganismComponent,
    canActivate: [AuthGuard],
    title: "Parameter Management for Locations"

  },
  {
    path: "parameter-mgt-stock-criteria",
    component: ParameterManagementStockCriteriaOrganismComponent,
    canActivate: [AuthGuard],
    title: "Parameter Management for Stock Criteria"
  },
  {
    path: "parameter-mgt-share-of-business",
    component: ParameterManagementShareOfBusinessOrganismComponent,
    canActivate: [AuthGuard],
    title: "Parameter Management for Share of Business"
  },
  {
    path: "parameterForm",
    component: ParameterManagementFormOrganismComponent,
    canActivate: [AuthGuard],
  },
  // {
  //     path: 'report',
  //     component: ReportsOrganismComponent,
  //     canActivate: [AuthGuard]
  // },
  {
    path: "stock",
    component: StockCriteriaComponent,
    canActivate: [AuthGuard],
    title: "Stock Criteria List"

  },
  {
    path: "stockForm",
    component: StockFormOrganismComponent,
    canActivate: [AuthGuard],
    title: "Stock Criteria Edit Page"
  },
  {
    path: "shareofbusiness",
    component: ShareOfBusinessOrganismComponent,
    canActivate: [AuthGuard],
    title: "Share of Business List"
  },
  {
    path: "shareofbusinessForm",
    component: ShareOfBusinessFormOrganismComponent,
    canActivate: [AuthGuard],
    title: "Share of Business Edit Page"
  },
  {
    path: "suggestion",
    component: DynamicNormOrganismComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "users",
    component: UserOrganismComponent,
    canActivate: [AuthGuard],
    title: "User Management List"
  },
  {
    path: "userForm",
    component: UserFormOrganismComponent,
    canActivate: [AuthGuard],
    title: "User Management Edit Page"
  },
  {
    path: "bulkUpload",
    component: BulkUploadOrganismComponent,
    canActivate: [AuthGuard],
    title: "bulk Upload"
  },
  {
    path: "revenueInsights",
    component: HomePageTemplateComponent,
    canActivate: [AuthGuard],
    title: "Flowsmart | Plan Smarter Execute better"
  },
  {
    path: "caching",
    component: CachingMoleculeComponent,
    canActivate: [AuthGuard],
    title: "Cache Management"
  },
  {
    path: "quote",
    component: InquiriesManagementOrganismComponent,
    canActivate: [AuthGuard],
    title: "Quote"
  },
  {
    path: "workflow",
    component: WorkflowOrganismComponent,
    canActivate: [AuthGuard],
    title: "Work Flow"
  },
  {
    path: "c-objects/:type",
    component: DynamicCustomObjectOrganismComponent,
    canActivate: [AuthGuard],
    title: "Sales Channel"
  },
  {
    path: "inventory-dashboard",
    component: InventoryPivotOrganismComponent,
    canActivate: [AuthGuard],
    title: "Inventory Snapshot"
  },
  {
    path: "variant-mix",
    component: SizeRatioConverterOrganismComponent,
    canActivate: [AuthGuard],
    title: "Product Size Ratio"
  },
  // {
  //   path: "sales-orders",
  //   component: SalesOrdersOrganismComponent,
  //   canActivate: [AuthGuard],
  //   title: "Sales Orders"
  // },
  {
    path: 'sales-orders',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./shared/organisms/sales-orders-organism/sales-orders.module').then(
        (m) => m.SalesOrdersModule
      ),
  },
  {
    path: "supplier-performance",
    component: SupplierPerformanceOrganismComponent,
    canActivate: [AuthGuard],
    title: "Supplier Performance"
  },
  {
    path: "re-ordering",
    component: ReOrderingOrganismComponent,
    canActivate: [AuthGuard],
    title: "Neemans Re Ordering"
  },
  {
    path: "reconciliation",
    component: ReconciliationOrganismComponent,
    canActivate: [AuthGuard],
    title: "Reconciliation"
  },
  {
    path: "sales-orders",
    component: SalesOrdersOrganismComponent,
    canActivate: [AuthGuard],
    title: "Sales Orders"
  }
  // {
  //     path: 'dashboard',
  //     component: MyNavTemplateComponent,
  //     canActivate: [AuthGuard]
  // },
  // {
  //     path: '**',
  //     redirectTo: '404',
  //     data: [{
  //       pageName: 'Not Found',
  //     },
  //     {
  //         path: '404',
  //         component: NotFoundOrganismComponent,
  //         pathMatch: 'full',
  //         data: [{
  //           pageName: 'Not Found',
  //     }
];
