<div class="fs-section-block fs-margin-0 header-background ">
  <div class="d-flex justify-content-between align-items-center">
    <div [ngClass]="currentClass" [ngStyle]="getHeadingStyle()">
      <span class="mat-headline">{{Heading}}</span>
      <p class="fs-hint-text">{{helpText}}</p>
    </div>
    <button *ngIf="showFilterButton" style="height: fit-content;" (click)="showFilters()" class="mat-button mat-primary mat-raised-button">
      <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="filter_alt"></mat-icon>
      Filters
    </button>
  </div>
</div>
