import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs/operators';
import { SizeRatioConverterService } from 'src/app/services/size-ratio-converter.service';

@Component({
  selector: 'app-size-ratio-converter-organism',
  templateUrl: './size-ratio-converter-organism.component.html',
  styleUrls: ['./size-ratio-converter-organism.component.scss']
})
export class SizeRatioConverterOrganismComponent implements OnInit {
  productForm: FormGroup;
  maxProducts = 25;
  products: FormArray;
  selectedProduct: any;
  filteredProducts: any;
  gridData: any;
  fieldData: any;
  headerData: any;
  visiblePOPUP: boolean;
  dynamic_headers: any;
  tableDataFetched: any = false;

  constructor(private fb: FormBuilder, private ratioConverterService: SizeRatioConverterService, private snack: MatSnackBar) {
    this.productForm = this.fb.group({
      product: ['', Validators.required],
      quantity: ['', [Validators.required, Validators.min(1)]],
    });
    this.products = this.fb.array([]);
  }
  ngOnInit() {
    this.initializeFilteredProducts();
  }
  initializeFilteredProducts() {
    this.filteredProducts = this.productForm?.get('product')?.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter(value => value && value.length >= 4),
      switchMap(value => this.getProductCodes(value))
    );
  }

  showSnackbar(message: string) {
    this.snack.open(message, 'close', {
      duration: 4000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }

  getProductCodes(searchTerm: string): Observable<any[]> {
    const trimmedTerm = searchTerm?.trim().toLowerCase() || '';
    if (!trimmedTerm) {
      return new Observable(observer => observer.next([]));
    }
    return this.ratioConverterService.getSuggestedAttributes(trimmedTerm).pipe(
      map((res: any) => {
        if (res && Array.isArray(res)) {
          return res;
        }
        return [];
      })
    );
  }

  onProductChange(event: any) {
    this.selectedProduct = event.option.value;
    this.productForm.get('product')?.setValue(this.selectedProduct);
  }

  handlePOPUP(event) {
    this.fieldData = event.fieldData;
    this.headerData = event.header;
    this.visiblePOPUP = true;
  }

  addProduct() {
    if (this.products.length < this.maxProducts) {
      if (this.productForm.valid) {
        this.gridData = null;
        this.tableDataFetched = false;
        this.dynamic_headers = null;
        const { product, quantity } = this.productForm.value;
        const productDetails = {
          code: product.trim(" ") ,
          quantity,
        };
        this.products.push(this.fb.group(productDetails));
        this.productForm.reset();
        this.productForm.get('product')?.setErrors(null);
        this.filteredProducts = null;
        this.initializeFilteredProducts();
      }
    } else {
      this.showSnackbar("You can only add up to 25 products")
    }
  }
  displayProduct(product: any): string {
    return product || '';
  }

  removeProduct(index: number) {
    this.products.removeAt(index);
  }

  convertToSizeRatio() {
    if (this.products.length > 0) {
      const payload = this.products.value;
      this.ratioConverterService?.getProductionRateData(payload).subscribe((response: any) => {
        if (response) {
          this.products.clear();
          this.productForm.reset();
          this.gridData = response['items'] || [];
          this.dynamic_headers = JSON.parse(response['headerContent']);
          this.tableDataFetched = true;
          this.initializeFilteredProducts();
        }
      }, (error) => {
        this.tableDataFetched = false;
        this.showSnackbar('Error while fetching the request.Please try again later');
      })
    }
  }

}
