import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { LocationsService } from '../../../../services/locations-service';
import { FormDataConstructorService } from '../../../../services/form-data-constructor.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: "app-locations-form-organism",
  templateUrl: "./locations-form-organism.component.html",
  styleUrls: ["./locations-form-organism.component.scss"],
})
export class LocationsFormOrganismComponent implements OnInit {
  formData: any;
  addFlag: boolean = true;
  locationId: string | null = null;
  isLoading = true;

  constructor(
    private locationsService: LocationsService,
    private formService: FormDataConstructorService,
    private snack: MatSnackBar,
    public dialogRef: MatDialogRef<LocationsFormOrganismComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { code?: string }
  ) {
    // Load form metadata
    this.locationsService.getLocationsForm().subscribe((res: any) => {
      let metaData = res.metaData;
      console.log("res1", res);

      if (data && data.code) {
        this.addFlag = false;
        this.locationsService
          .getLocationByCode(data.code)
          .subscribe((locationData: any) => {
            console.log("res2", locationData);
            this.locationId = locationData.id;

            this.locationsService.getLocationAttributes(data.code).subscribe(
              (attributeData: any) => {
                console.log("res3", attributeData);
                locationData.dynamicAttrs = attributeData.attributes;
                const processedAttrs = this.processAttrData(locationData);
                const processedMetaData = this.processMetaData(
                  metaData,
                  processedAttrs
                );
                this.formData =
                  this.formService.formConstructor(processedMetaData);
              },
              (error) => {
                this.isLoading = false;
              },
              () => {
                this.isLoading = false;
              }
            );
          });
      } else {
        this.addFlag = true;
        metaData = metaData.filter(
          (each) =>
            each.identifier !== "createdTime" &&
            each.identifier !== "modifiedTime"
        );
        this.formData = this.formService.formConstructor(metaData);
        this.isLoading = false;
      }
    });
  }

  ngOnInit(): void {}

  // Process dynamic attributes into form data
  processAttrData(data: any) {
    data.dynamicAttrs.forEach((each: any) => {
      const key = each.attributeName;
      const val = each.attributeValue;
      data[key] = val;
    });
    return data;
  }

  // processMetaData(metaData, data) {
  //   const processedMetaData = [...metaData];
  //   Object.keys(data).map((singleKey) => {
  //     metaData.map((eachMetaData) => {
  //       if (eachMetaData && singleKey === eachMetaData.identifier) {
  //         if (eachMetaData.dynamic) {
  //           data &&
  //             data.dynamicAttrs.map((dynamicAttr) => {
  //               if (singleKey === dynamicAttr.attributeName) {
  //                 eachMetaData["id"] = dynamicAttr.id;
  //               }
  //             });
  //         }
  //         eachMetaData.value = data[singleKey];
  //         if (singleKey === "createdTime" || singleKey === "modifiedTime") {
  //           eachMetaData.isReadOnly = true;
  //           processedMetaData.push(eachMetaData);
  //         } else {
  //           eachMetaData.isReadOnly = false;
  //           processedMetaData.push(eachMetaData);
  //         }
  //       }
  //     });
  //   });
  //   console.log('processedMetaData', processedMetaData)
  //   return processedMetaData;
  // }

  processMetaData(metaData, data) {
    // Create a new array from metaData
    const processedMetaData = [...metaData];

    // Create a Map to track unique metadata by identifier
    const processedMetaDataMap = new Map(
      processedMetaData.map((item) => [item.identifier, item]) // Pre-fill with metaData items
    );

    // Process data and add/update in the Map
    Object.keys(data).forEach((singleKey) => {
      metaData.forEach((eachMetaData) => {
        if (eachMetaData && singleKey === eachMetaData.identifier) {
          if (eachMetaData.dynamic) {
            data.dynamicAttrs.forEach((dynamicAttr) => {
              if (singleKey === dynamicAttr.attributeName) {
                eachMetaData["id"] = dynamicAttr.id;
              }
            });
          }
          eachMetaData.value = data[singleKey];
          eachMetaData.isReadOnly =
            singleKey === "createdTime" || singleKey === "modifiedTime";

          // Add/update the metadata in the Map
          processedMetaDataMap.set(eachMetaData.identifier, eachMetaData);
        }
      });
    });

    // Convert the Map back to an array
    const deduplicatedMetaData = Array.from(processedMetaDataMap.values());
    console.log("processedMetaData", deduplicatedMetaData);
    return deduplicatedMetaData;
  }

  // Map metaData with formData
  // processMetaData(metaData: any[], data: any) {
  //   const processedMetaData = [...metaData];

  //   // Map values from data to metaData
  //   processedMetaData.forEach((eachMetaData) => {
  //     const key = eachMetaData.identifier;
  //     if (data[key] !== undefined) {
  //       eachMetaData.value = data[key];
  //     }
  //     eachMetaData.isReadOnly = ["createdTime", "modifiedTime"].includes(key);
  //   });

  //   return processedMetaData;
  // }

  // processMetaData(metaData: any[], data: any) {
  //   const processedMetaData = [];
  //   Object.keys(data).forEach((singleKey) => {
  //     metaData.forEach((eachMetaData) => {
  //       if (eachMetaData && singleKey === eachMetaData.identifier) {
  //         if (eachMetaData.dynamic) {
  //           data.dynamicAttrs.forEach((dynamicAttr: any) => {
  //             if (singleKey === dynamicAttr.attributeName) {
  //               eachMetaData['id'] = dynamicAttr.id;
  //             }
  //           });
  //         }
  //         eachMetaData.value = data[singleKey];
  //         if (singleKey === 'createdTime' || singleKey === 'modifiedTime') {
  //           eachMetaData.isReadOnly = true;
  //         } else {
  //           eachMetaData.isReadOnly = false;
  //         }
  //         processedMetaData.push(eachMetaData);
  //       }
  //     });
  //   });
  //   return processedMetaData;
  // }

  // Submit form
  submitForm(formValues: any) {
    this.locationsService
      .saveLocation(formValues, this.formData, this.addFlag, this.locationId)
      .subscribe(() => {
        const message = this.addFlag
          ? "Added successfully"
          : "Changes saved successfully";
        this.snack.open(message, "close", {
          duration: 4000,
          horizontalPosition: "center",
          verticalPosition: "top",
        });
        this.dialogRef.close(true); // Close modal and pass data
      });
  }

  // Close modal without saving
  onCancel(): void {
    this.dialogRef.close(false);
  }
}
