import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-header-content-help-text',
  templateUrl: './header-content-help-text.component.html',
  styleUrls: ['./header-content-help-text.component.scss']
})
export class HeaderContentHelpTextComponent {
  @Input() Heading;
  @Input() helpText;
  @Input() showFilterButton: boolean = false;
  @Output() filterButtonClicked = new EventEmitter<void>();

  currentClass: string = '';
  showOverlay:boolean = false;
  getHeadingStyle() {
    this.currentClass = '';
    if (!this.Heading?.includes("fetch")) {
      this.currentClass = 'fade-in';
      return {};
    } else {
      return {
        'font-style': 'italic',
        'color': 'grey',
        'font-weight': '200'
      };
    }
  }

  showFilters() {
    this.filterButtonClicked.emit();
  }

}
