<div class="fs-padding">
  <div class="fs-section-block fs-margin-0 header-background ">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <span class="mat-headline">{{reportNameWithSpace}}</span>
        <p class="fs-hint-text">{{subHeadings[reportNameWithSpace]}}</p>
      </div>
      <button style="height: fit-content;" (click)="showFilters()" class="mat-button mat-primary mat-raised-button">
        <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="filter_alt"></mat-icon>
        Filters
      </button>
    </div>
  </div>
    <app-material-master-filter-molecule [hidden]="!showOverlay" (closeFiltersOutput)="closeFilters()"  [changesDetected]="detectChangesOnFilters" [productTypes]="['TRADED','FG']" (emitAppliedFilters)="applyFilters($event)"></app-material-master-filter-molecule>
    
</div>

<div class="text-right fs-margin-0-1"><span>Reports are generated as on <strong>{{generatedDate | date}}</strong></span></div>
<app-filter-bay-reusable [MaterialMaster]="true" *ngIf="displayAppliedFilters" [addFilters]="displayAppliedFilters"></app-filter-bay-reusable>
<div *ngIf="dynamic_headers?.length>0">
  <app-syncfusion-grid [reportName]="reportName" [dynamic_headers]="dynamic_headers" [count]="count" [data]="data" [freezeColumns]="6"
    *ngIf="tableDataFetched" (dataEmitForPOPUP)="handlePOPUP($event)" (navigateGrid)="navigateGrid($event)" 
    (reloadPageEvent)="reloadPage()"></app-syncfusion-grid>
</div>

<app-popup-reuse-organsim *ngIf="fieldData && headerData" [showPOPUP]="visiblePOPUP" [fieldData]="fieldData"
  [headerData]="headerData" (POPUPFeedback)="visiblePOPUP=$event"></app-popup-reuse-organsim>

