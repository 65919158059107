import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ShareOfBusinessService } from '../../../../services/shareofbusiness.service';
import { FormDataConstructorService } from '../../../../services/form-data-constructor.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-shareofbusiness-form-organism',
  templateUrl: './shareofbusiness-form-organism.component.html',
  styleUrls: ['./shareofbusiness-form-organism.component.scss']
})
export class ShareOfBusinessFormOrganismComponent implements OnInit {
  formData: any;
  addFlag = true;
  shareOfBusinessId: string;
  isLoading = true

  constructor(
    public dialogRef: MatDialogRef<ShareOfBusinessFormOrganismComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private shareOfBusinessService: ShareOfBusinessService,
    private snack: MatSnackBar,
    private formService: FormDataConstructorService
  ) {
    this.initializeForm();
  }

  ngOnInit(): void {}

  private initializeForm(): void {
    this.shareOfBusinessService.getShareOfBusinessForm().subscribe((res: any) => {
      const metaData = res.shareOfBusinessMetadataList;
      if (this.data && this.data.sobid) {
        const id = this.data.sobid;
        this.addFlag = false;
        this.loadFormData(id, metaData);
      } else {
        this.addFlag = true;
        this.shareOfBusinessId = null;
        this.loadEmptyForm(metaData);
      }
    },
    (error) => {
      this.isLoading = false;
    },
    () => {
      this.isLoading = false;
    }
  );
  }

  private loadFormData(id: string, metaData: any[]): void {
    this.shareOfBusinessService.getShareofBusinessById(id).subscribe((data: any) => {
      if (data) {
        this.shareOfBusinessId = data.id;

        // Fetch attributes for the retrieved Share of Business
        this.shareOfBusinessService.getShareOfBusinessAttributes(this.shareOfBusinessId).subscribe((attributeData: any) => {
          data.dynamicAttrs = attributeData.shareOfBusinessAttributes;
          const processedAttrs = this.processAttrData(data);
          const processedMetaData = this.processMetaData(metaData, processedAttrs);
          this.formData = this.formService.formConstructor(processedMetaData);
          console.log("Form data loaded:", this.formData); // Debugging log
        },
        (error) => {
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
      }
    });
  }

  private loadEmptyForm(metaData: any[]): void {
    metaData = metaData.filter(each => each.identifier !== 'createdTime' && each.identifier !== 'modifiedTime');
    this.formData = this.formService.formConstructor(metaData);
    console.log("Empty form data loaded:", this.formData); // Debugging log
  }

  private processAttrData(data: any): any {
    data.dynamicAttrs?.forEach((each: any) => {
      const key = each.attributeName;
      const val = each.attributeValue;
      data[key] = val;
    });
    return data;
  }
      // Map metaData with formData
      processMetaData(metaData: any[], data: any) {
        const processedMetaData = [...metaData];
      
        // Map values from data to metaData
        processedMetaData.forEach((eachMetaData) => {
          const key = eachMetaData.identifier;
          if (data[key] !== undefined) {
            eachMetaData.value = data[key];
          }
          eachMetaData.isReadOnly = ["createdTime", "modifiedTime"].includes(key);
        });
      
        return processedMetaData;
      }
      
  // private processMetaData(metaData: any[], data: any): any[] {
  //   const processedMetaData: any[] = [];
  //   Object.keys(data).forEach(singleKey => {
  //     metaData.forEach(eachMetaData => {
  //       if (eachMetaData && singleKey === eachMetaData.identifier) {
  //         if (eachMetaData.dynamic) {
  //           data.dynamicAttrs?.forEach((dynamicAttr: any) => {
  //             if (singleKey === dynamicAttr.attributeName) {
  //               eachMetaData['id'] = dynamicAttr.id;
  //             }
  //           });
  //         }
  //         eachMetaData.value = data[singleKey];
  //         eachMetaData.isReadOnly = singleKey === 'createdTime' || singleKey === 'modifiedTime';
  //         processedMetaData.push(eachMetaData);
  //       }
  //     });
  //   });
  //   return processedMetaData;
  // }

  submitForm(formValues: any): void {
    let resultJson: any = { id: this.shareOfBusinessId };
    let resultJsonAttributes: any[] = [];
  
    this.formData.forEach((eachFormData: any) => {
      if (eachFormData.dynamic) {
        const attributeId = this.addFlag ? null : eachFormData.id;
        const attributeValue = formValues[eachFormData.identifier]?.value;
    
        // Add only if attributeValue exists
        if (attributeValue) {
          resultJsonAttributes.push({
            id: attributeId,
            attributeName: eachFormData.identifier,
            attributeValue: attributeValue
          });
        }
      } else {
        const value = formValues[eachFormData.identifier]?.value;
    
        // Add only if value exists
        if (value) {
          if (eachFormData.dataType === "Array") {
            resultJson[eachFormData.identifier] = Array.isArray(value)
              ? value
              : value.split(',');
          } else {
            resultJson[eachFormData.identifier] = value;
          }
        }
      }
    });
    

    // Confirm payload data in console
    console.log('Final resultJson:', resultJson);
    console.log('Final resultJsonAttributes:', resultJsonAttributes);
 
    this.shareOfBusinessService.saveShareOfBusiness(resultJson).subscribe(
      (response: any) => {
        if (response && typeof response === 'object' && response.id) {
          resultJsonAttributes = resultJsonAttributes.map(obj => ({
            ...obj,
            sobid: response.id,
          }));
          this.shareOfBusinessService.saveShareOfBusinessAttributes(resultJsonAttributes).subscribe(
            () => this.showSnackBarMessage(this.addFlag ? 'Added successfully' : 'Changes saved successfully'),
            (error) => {
              console.error('Error saving attributes:', error);
              this.showSnackBarMessage('Error saving attributes');
            }
          );
        } else {
          console.error('Invalid API response: ID not found.');
          this.showSnackBarMessage('Invalid API response: ID not found.');
        }
      },
      (error) => {
        console.error('Error in saveShareOfBusiness:', error);
        this.showSnackBarMessage('Error saving Share of Business');
      }
    );
  }
 
  private showSnackBarMessage(message: string): void {
    this.snack.open(message, 'close', {
      duration: 4000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
    this.dialogRef.close(true); // Close the dialog and return success flag
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }
}
