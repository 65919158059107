import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomObjectsService } from 'src/app/services/custom-objects.service';

@Component({
  selector: 'app-custom-objects-object-type-molecule',
  templateUrl: './custom-objects-object-type-molecule.component.html',
  styleUrls: ['./custom-objects-object-type-molecule.component.scss'],
  providers: [DatePipe],
})
export class CustomObjectsObjectTypeMoleculeComponent implements OnInit {
  @Input() config: any;
  dynamicForm!: FormGroup;
  configName: any;
  customObjId:any;

  constructor(private snack: MatSnackBar, private fb: FormBuilder,private customService: CustomObjectsService,private datePipe: DatePipe) { }
  ngOnInit() {
    if (this.config) {
      this.configName = this.config?.code;
      this.generateForm();
      this.getCustomObjectData();
    }
  }
  getCustomObjectData(){
    const pageData: any = {};
    this.customService.getPaginatedCustomObjList(this.configName, pageData).subscribe((res) => {
      if (res && res['items'] && res['items'].length > 0) {
        const data = res['items'][0];
        this.customObjId = data.id;
        const patchValues: any = {};
        this.config?.fields.forEach((field: any) => {
          const fieldValue = data[field.code];
          if (fieldValue !== undefined) {
            patchValues[field.code] = fieldValue;
          }
        });
  
        this.dynamicForm.patchValue(patchValues);
      }
    });
  }
  generateForm() {
    const controls = this.config?.fields
      .filter((field: any) => field.initial)
      .reduce((acc: any, field: any) => {
        acc[field.code] = this.fb.control('', this.mapValidators(field?.validators));
        return acc;
      }, {});

    this.dynamicForm = this.fb.group(controls);
  }
  mapValidators(validators: Record<string, any>): any[] {
    const validatorMapping: Record<string, (value: any) => any> = {
      required: () => Validators.required,
      minLength: (value: number) => Validators.minLength(value),
      maxLength: (value: number) => Validators.maxLength(value),
      pattern: (value: string) => Validators.pattern(value),
    };

    return Object.entries(validators)
      .map(([key, value]) => validatorMapping[key]?.(value))
      .filter(Boolean);
  }
  markAllControlsAsTouched(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormGroup) {
        this.markAllControlsAsTouched(control);
      } else {
        control?.markAsTouched();
      }
    });
  }
  showSnackbar(message: string) {
    this.snack.open(message, 'close', {
      duration: 4000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }
  saveForm() {
    this.markAllControlsAsTouched(this.dynamicForm);
    if (this.dynamicForm.valid) {
      let payload = this.dynamicForm.value;
      if(payload.plannedETA){
        const formattedDate = this.datePipe.transform(payload.plannedETA, "dd-MMM-yyyy");
        console.log(formattedDate,"dt")
        payload = {...payload,plannedETA:formattedDate};
      }
      if (this.customObjId) {
        this.customService.updateCustomObj(this.configName, payload, this.customObjId).subscribe((res: any) => {
          this.showSnackbar('Updated settings successfully!');
          console.log('Response from save API:', res);
          this.getCustomObjectData();
        }, (error) => {
          this.showSnackbar('Unable to update settings');
          console.log(error);
        });


      } else {
        this.customService.createCustomObj(this.configName, payload).subscribe((res) => {
          this.showSnackbar('Your settings have been successfully saved!');
          console.log('Response from save API:', res);
          this.getCustomObjectData();
        },
          (error) => {
            this.showSnackbar('Unable to save settings.Please try again later');
          })
      }
    }
  }

  getFieldByCode(code: string) {
    return this.config?.fields?.find((field:any) => field.code === code);
  }

}
