import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SalesOrdersOrganismComponent } from './sales-orders-organism.component';

const routes: Routes = [
  { path: '', component: SalesOrdersOrganismComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SalesOrdersRoutingModule { }
