
<ng-template #overlayPanel>
    <div class="overlay-panel"  [@childAnimation] >
        <div class="overlay-header">
            <div>{{ isEditMode ? 'Edit' : 'Create' }} {{config?.title}}</div>
            <div>
                <mat-icon class="close-icon" (click)="saveForm()">save</mat-icon>
                <mat-icon class="close-icon" (click)="closeOverlay()">close</mat-icon>
            </div>
        </div>
        <div class="fields-container" *ngIf="dynamicForm">
            <div *ngFor="let field of config.fields" [formGroup]="dynamicForm">
                <div *ngIf="field.initial">
                    <div [ngSwitch]="field.display_type">
                        <!-- Input Field -->
                        <div *ngSwitchCase="'input'">
                            <mat-form-field>
                                <mat-label class="label">{{ field.label }}</mat-label>
                                <input matInput [formControlName]="field.code" placeholder="{{ field.label }}" [readonly]="true">
                                <mat-hint align="start">
                                    <mat-icon class="info-icon">info</mat-icon>
                                    <span class="info-text">{{ field.info }}</span>
                                </mat-hint>
                                <mat-error *ngIf="dynamicForm.get(field.code).hasError('required')">
                                    {{ field.label }} is required.
                                </mat-error>
                                <mat-error *ngIf="dynamicForm.get(field.code).hasError('minlength')">
                                    Minimum {{ field.validators?.minLength }} characters are required.
                                </mat-error>
                                <mat-error *ngIf="dynamicForm.get(field.code).hasError('maxlength')">
                                    Maximum {{ field.validators?.maxLength }} characters are required.
                                </mat-error>
                                <mat-error *ngIf="dynamicForm.get(field.code).hasError('pattern')">
                                    Invalid format for {{ field.label }}.
                                </mat-error>
                            </mat-form-field>
                        </div>
    
                        <!-- Select Field -->
                        <div *ngSwitchCase="'select'">
                            <mat-form-field>
                                <mat-label class="label">{{ field.label }}</mat-label>
                                <mat-select [formControlName]="field.code">
                                    <mat-option *ngFor="let option of field.values"
                                        [value]="option.code">{{option.label}}</mat-option>
                                </mat-select>
                                <mat-hint align="start">
                                    <mat-icon class="info-icon">info</mat-icon>
                                    <span class="info-text">{{ field.info }}</span>
                                </mat-hint>
                                <mat-error *ngIf="dynamicForm.get(field.code).hasError('required')">This field is
                                    required.</mat-error>
                            </mat-form-field>
                        </div>
    
                        <!-- Radio Field -->
                        <div *ngSwitchCase="'radio'" class="radio-cont">
                            <div class="toggle-cont">
                                <mat-label class="label" style="font-weight: 500;">{{ field.label }}</mat-label>
                                <!-- Toggle Control -->
                                <mat-slide-toggle [formControlName]="field.code" [disabled]="!field.enabled"
                                    (change)="onToggleChange($event, field)">
                                </mat-slide-toggle>
                            </div>
                           
                            <div class="d-flex" style="line-height: 0.8;">
                                <mat-icon
                                    style="font-size: 0.6em;margin-right: 2px;width: 1em;height: 1em;color: #ffa000;">info</mat-icon>
                                <span style="font-size: 0.7em;font-style: italic;color: #000;">{{ field.info }}</span>
                            </div>
                            <mat-error *ngIf="dynamicForm.get(field.code).hasError('required')">
                                Please select an option for {{ field.label }}.
                            </mat-error>
                        </div>
    
                        <!-- Calendar Field (Date Picker) -->
                        <div *ngSwitchCase="'calendar'">
                            <mat-form-field>
                                <mat-label class="label">{{ field.label }}</mat-label>
                                <input matInput [matDatepicker]="picker" [formControlName]="field.code"
                                    placeholder="Choose a date">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-hint align="start">
                                    <mat-icon class="info-icon">info</mat-icon>
                                    <span class="info-text">{{ field.info }}</span>
                                </mat-hint>
                                <mat-error *ngIf="dynamicForm.get(field.code).hasError('required')">
                                    {{ field.label }} is required.
                                </mat-error>
                                <mat-error *ngIf="dynamicForm.get(field.code).hasError('matDatepickerParse')">
                                    Invalid date format.
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <!-- Textarea -->
                        <div *ngSwitchCase="'textarea'">
                            <mat-form-field appearance="fill">
                                <mat-label class="label">{{ field.label }}</mat-label>
                                <textarea matInput [formControlName]="field.code" placeholder="{{ field.label }}"
                                    rows="4"></textarea>
                                <mat-hint align="start">
                                    <mat-icon class="info-icon">info</mat-icon>
                                    <span class="info-text">{{ field.info }}</span>
                                </mat-hint>
                                <mat-error *ngIf="dynamicForm.get(field.code).hasError('required')">
                                    {{ field.label }} is required.
                                </mat-error>
                                <mat-error *ngIf="dynamicForm.get(field.code).hasError('minlength')">
                                    Minimum {{ field.validators?.minLength }} characters are required.
                                </mat-error>
                                <mat-error *ngIf="dynamicForm.get(field.code).hasError('maxlength')">
                                    Maximum{{ field.validators?.maxLength }} characters are required.
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>