<div class="sales-main-container">
    <ng-container *ngIf="cartVisible; else showContent">
        <div class="cart-products-list-container">
            <div class="cart-header">
                <mat-icon class="back-arrow" (click)="goBack()">keyboard_arrow_left</mat-icon>
                <span class="mat-headline">Items in Cart</span>
            </div>
            <div class="sales-cart-list-overflow-container">
                <div *ngFor="let product of cartItems" class="product-card-cont">
                    <div class="product-card">
                        <div class="sales-product-image-container">
                            <img [src]="product.imageUrl ? product.imageUrl : '/assets/img/Image_Coming_Soon.jpg'"
                                alt="{{ product.name }}" (mouseenter)="changeActiveImage(product.imageUrl)"
                                (mouseleave)="changeActiveImage('')" />
                        </div>
                        <div *ngIf="product.imageUrl"
                            class="{{product.imageUrl === showLargeImageId ? 'speech-bubble' : 'display-hide'}}">
                            <img src="{{product.imageUrl}}" alt="Image" />
                        </div>
                        <div class="sales-product-details-container">
                            <p class="product-title">{{ product.materialName }}</p>
                            <div class="quantity-input-container">
                                <mat-form-field class="quantity-mat-field green-input">
                                    <input type="number" matInput [(ngModel)]="product.orderQty" placeholder="Frh Qty">
                                </mat-form-field>
                                <mat-form-field class="quantity-mat-field red-input">
                                    <input type="number" matInput [(ngModel)]="product.replacementQty"
                                        placeholder="Rpl Qty">
                                </mat-form-field>
                            </div>
                            <div class="d-flex justify-content-between align-items-center">
                                <button (click)="updateItem(product)">Update Item</button>
                                <button (click)="deleteItem(product)">Delete Item</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <button class="mat-button mat-primary mat-raised-button" (click)="checkout()">Place Order</button>
        </div>
    </ng-container>
    <ng-template #showContent>
        <ng-container *ngIf="isCreated; else welcomeScreen">
            <div class="sales-content-container">
                <!-- creating a order container -->
                <div class="sales-fields-search-container">
                    <div class="sales-fields-container">
                        <mat-icon class="back-arrow" (click)="goBack()">keyboard_arrow_left</mat-icon>
                        <span>Ordering for</span>
                        <button mat-button (click)="openCustomerDialog()"   id="customerButton"
                            [ngClass]="selectedCustomer ? 'customer-selected' : 'customer-default'"
                            class="no-border-button">
                            {{ selectedCustomer ? selectedCustomer.contactName : 'Select Customer' }}
                        </button>
                    </div>
                    <div class="sales-search-cart-container">
                        <div class="search-container" *ngIf="showProducts">
                            <input matInput [(ngModel)]="searchProductText" (keyup.enter)="filterProducts()"
                                placeholder="Search Products">
                            <mat-icon matSuffix (click)="searchProductText ? clearProductSearch() : filterProducts()">{{
                                searchProductText ? 'close' : 'search'}}</mat-icon>
                        </div>
                        <div class="cart-container" (click)="viewCart()" *ngIf="showProducts && !isStockTake">
                            <div class="cart-icon-wrapper">
                                <mat-icon class="cart-icon">shopping_cart</mat-icon>
                                <span class="cart-count">{{ cartItems.length }}</span>
                            </div>
                            <span class="cart-text">Cart</span>
                        </div>
                    </div>

                </div>

                <!-- rendering products below -->
                <ng-container *ngIf="showProducts">
                    <ng-container *ngIf="loading; else productGrid">
                        <div class="loading-text">Loading Products...</div>
                    </ng-container>
                    <ng-template #productGrid>
                        <div class="sales-product-list-overflow-container">
                            <div *ngFor="let product of filteredProductList" class="product-card-cont">
                                <div class="product-card">
                                    <div class="sales-product-image-container">
                                        <img [src]="product.imageUrl ? product.imageUrl : '/assets/img/Image_Coming_Soon.jpg'"
                                            alt="{{ product.name }}" (mouseenter)="changeActiveImage(product.imageUrl)"
                                            (mouseleave)="changeActiveImage('')" />
                                    </div>
                                    <div *ngIf="product.imageUrl"
                                        class="{{product.imageUrl === showLargeImageId ? 'speech-bubble' : 'display-hide'}}">
                                        <img src="{{product.imageUrl}}" alt="Image" />
                                    </div>
                                    <div class="sales-product-details-container">
                                        <p class="product-title">{{ product.name }}</p>
                                        <div class="quantity-input-container">
                                            <mat-form-field class="quantity-mat-field" [ngClass]="isStockTake ? '' : 'green-input'">
                                                <input style="border: none;outline: none;" type="number" matInput [(ngModel)]="product.orderQty"
                                                    (ngModelChange)="onQuantityChange(product)" [placeholder]="isStockTake ? 'Stock' : 'Frh Qty'">
                                            </mat-form-field>
                                            <mat-form-field class="quantity-mat-field red-input" *ngIf="!isStockTake">
                                                <input type="number" matInput [(ngModel)]="product.replacementQty"
                                                    (ngModelChange)="onQuantityChange(product)"
                                                    placeholder="Rpl Qty">
                                            </mat-form-field>
                                        </div>
                                        <button class="mat-button mat-primary mat-raised-button"
                                            (click)="isStockTake ? updateStock(product) : addToCart(product)"
                                            [disabled]="!isButtonEnabledMap[product.code]">
                                            {{ isStockTake ? 'Update Stock' : 'Add to Cart' }}
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>

                    <ng-template *ngIf="filteredProductList.length == 0">
                        <div class="no-products-found">
                            No Products Found for "{{ searchProductText }}"
                        </div>
                    </ng-template>
                </ng-container>

            </div>
        </ng-container>
        <!-- welocme screen before order creation -->
        <ng-template #welcomeScreen>
            <div class="create-order-button-container">
                <button class="mat-button mat-primary mat-raised-button" (click)="createOrder()">Create
                    Order</button>
                <button class="mat-button mat-primary mat-raised-button" (click)="stockTake()">Stock
                    Take</button>
                <!-- <button class="mat-button mat-primary mat-raised-button" (click)="createOrder()">Update
                    Jio Code</button> -->
            </div>
        </ng-template>
    </ng-template>
    <!-- Search Customer Dialog Template -->
    <ng-template #customerDialogTemplate>
        <mat-dialog-content class="customer-list-overlay-container">
            <div class="search-customer-container">
                <input matInput [(ngModel)]="searchCustomerText" (keyup.enter)="filterCustomers()"
                    placeholder="Search Customer" />
                <mat-icon matSuffix (click)="searchCustomerText ? clearSearch() : filterCustomers()"> {{
                    searchCustomerText ? 'close' : 'search' }}</mat-icon>
            </div>
            <mat-list class="customer-list-container">
                <mat-list-item class="list-item" *ngFor="let customer of filteredCustomers"
                    (click)="selectCustomer(customer)">
                    <div class="customer-info">
                        <span class="contact-name">{{ customer.contactName }}</span>
                        <span class="company-name">{{ customer.partnerId }}</span>
                    </div>

                </mat-list-item>
            </mat-list>
        </mat-dialog-content>
    </ng-template>
</div>