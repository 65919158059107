<ng-template #overlayPanel>
    <div class="overlay-panel" [@childAnimation]>
        <div class="overlay-header">
            <div> Filters </div>
            <mat-icon class="close-icon" (click)="closeOverlay()">close</mat-icon>
        </div>
        <div class="overlay-body-scrollable-container">
            <div *ngFor="let filter of filterData">
                <div class="filter-section">
                    <!-- Multi-Select Checkboxes -->
                    <ng-container *ngIf="filter.filterType === 'Checkbox'">
                        <mat-form-field>
                            <mat-label>{{ filter.label }}</mat-label>
                            <mat-select [(ngModel)]="selectedFilters[filter.code]" multiple>
                                <mat-option *ngFor="let option of filter.values" [value]="option">
                                    {{ option }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>
                    <!-- Range Filters -->
                    <ng-container *ngIf="filter.filterType === 'range'">
                        <div class="range-filter-container"  *ngIf="filter.type === 'Date'">
                            <label>{{ filter.label }}</label>
                            <div class="range-filter">
                                <mat-form-field appearance="fill">
                                    <mat-label>From</mat-label>
                                    <input matInput [matDatepicker]="fromDate" (ngModelChange)="validateDateRange(filter.code)"
                                        [(ngModel)]="selectedFilters[filter.code].from" placeholder="From Date">
                                    <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
                                    <mat-datepicker #fromDate></mat-datepicker>
                                </mat-form-field>
    
                                <mat-form-field appearance="fill">
                                    <mat-label>To</mat-label>
                                    <input matInput [matDatepicker]="toDate" [(ngModel)]="selectedFilters[filter.code].to"
                                        placeholder="To Date" (ngModelChange)="validateDateRange(filter.code)">
                                    <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
                                    <mat-datepicker #toDate></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="range-filter-container" *ngIf="filter.type === 'NUMERIC'">
                            <label>{{ filter.label }}</label>
                            <div class="range-filter">
                                <mat-form-field appearance="fill">
                                    <mat-label>From</mat-label>
                                    <input matInput type="number" [(ngModel)]="selectedFilters[filter.code].from"
                                        placeholder="From Value">
                                </mat-form-field>
    
                                <mat-form-field appearance="fill">
                                    <mat-label>To</mat-label>
                                    <input matInput type="number" [(ngModel)]="selectedFilters[filter.code].to"
                                        placeholder="To Value">
                                </mat-form-field>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="overlay-bottom-buttons-container">
            <button style="height: fit-content;" (click)="applyFilters()"
                class="mat-button mat-primary mat-raised-button">
                Apply
            </button>
            <button style="height: fit-content;" (click)="cancelFilters()"
                class="mat-button mat-primary mat-raised-button cancel-button">
                Cancel
            </button>
        </div>
    </div>
</ng-template>