import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { animate, animateChild, query, style, transition, trigger } from '@angular/animations';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppSettingService } from 'src/app/services/app-setting.service';
import { CustomObjectsService } from 'src/app/services/custom-objects.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-custom-overlay-template',
  templateUrl: './custom-overlay-template.component.html',
  styleUrls: ['./custom-overlay-template.component.scss'],
  animations: [
    trigger('slide', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms ease',
          style({ opacity: 1 })
        ),
        query("@*", [animateChild()], { optional: true })
      ]),
      transition(':leave', [
        query("@*", [animateChild()], { optional: false }),
      ]),
    ]),
    trigger('childAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('800ms cubic-bezier(0.2, 1, 0.3, 1)',
          style({ transform: 'translateX(0%)' })
        )
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('300ms ease',
          style({
            transform: 'translateX(100%)',
            boxShadow: '0px 0 00px 0px rgba(87,73,86,0.0)'
          }
          )
        )
      ])
    ])
  ],
  providers: [DatePipe],
})
export class CustomOverlayTemplateComponent {
  @ViewChild('overlayPanel', { static: false }) overlayPanel!: TemplateRef<any>;
  @Output() customObjListUpdated: EventEmitter<any> = new EventEmitter<any>();
  @Input() config: any;
  @Input() data: any;
  @Output() overlayClosed = new EventEmitter<void>();
  @Output() customObjListFetched = new EventEmitter<any>();
  overlayRef!: OverlayRef;
  dynamicForm!: FormGroup;
  isEditMode = false;
  visiblePOPUP: boolean;
  configName: any;
  editObjId: any;

  constructor(private fb: FormBuilder, private overlay: Overlay, private renderer: Renderer2,
    private viewContainerRef: ViewContainerRef, private snack: MatSnackBar, private settingsService: AppSettingService,
    private customService: CustomObjectsService, private datePipe: DatePipe) { }
  ngOnInit() {
    if (this.config) {
      this.configName = this.config?.code;
      this.generateForm();
      // this.createOverlay();
    }
  }
  createOverlay() {
    this.configName = this.config?.code;
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
    if (!this.overlayRef) {
      this.generateForm();
      this.overlayRef = this.overlay.create({
        hasBackdrop: true,
        backdropClass: 'overlay-backdrop',
        positionStrategy: this.overlay.position().global().right('0px').top('0px'),
        scrollStrategy: this.overlay.scrollStrategies.block(),
        height: '100vh',
        width: '400px'
      });
      const templatePortal = new TemplatePortal(this.overlayPanel, this.viewContainerRef);
      // Promise.resolve().then(() => this.overlayRef.attach(templatePortal));
      this.overlayRef?.attach(templatePortal);
      this.overlayRef.backdropClick().subscribe(() => {
        this.closeOverlay();
      });
    }
  }
  closeOverlay() {
    if (this.overlayRef) {
      this.overlayRef?.dispose();
      this.overlayRef = null!;
      this.isEditMode = false;
      this.dynamicForm.reset();
    }
  }
  generateForm() {
    this.isEditMode = false;
    const controls = this.config?.fields
      .filter((field: any) => field.initial)
      .reduce((acc: any, field: any) => {
        acc[field.code] = this.fb.control('', this.mapValidators(field?.validators || {}));
        return acc;
      }, {});

    this.dynamicForm = this.fb.group(controls);
    this.dynamicForm.patchValue({ code: this.data.rowData.sku});
    if (this.data.list) {
      this.data.list.forEach((item: any) => {
        if (item.code === this.data.rowData.sku) {
          this.isEditMode = true;
          if (this.data.rowData.plannedETA) {
            this.data.rowData.plannedETA = new Date(this.data.rowData.plannedETA);
          }
          this.editObjId = item.id;
          this.dynamicForm.patchValue(this.data.rowData);
        }
      })
    }
  }
  mapValidators(validators: Record<string, any>): any[] {
    const validatorMapping: Record<string, (value: any) => any> = {
      required: () => Validators.required,
      minLength: (value: number) => Validators.minLength(value),
      maxLength: (value: number) => Validators.maxLength(value),
      pattern: (value: string) => Validators.pattern(value),
    };
    return Object.entries(validators)
      .map(([key, value]) => value !== false ? validatorMapping[key]?.(value) : null)  // Only map valid values
      .filter(Boolean);
  }
  createForm() {
    this.isEditMode = false;
    this.createOverlay();
  }
  showSnackbar(message: string) {
    this.snack.open(message, 'close', {
      duration: 4000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }
  getCustomObjList() {
    this.customService.getPaginatedCustomObjList(this.configName, {}).subscribe((res: any) => {
      if (res) {
        this.data = res?.items;
        this.customObjListUpdated.emit(this.data);
      }
    }, (error) => {
      console.error(error);
    })
  }
  markAllControlsAsTouched(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormGroup) {
        this.markAllControlsAsTouched(control);
      } else {
        control?.markAsTouched();
      }
    });
  }
  onToggleChange(event: MatSlideToggleChange, field: any) {
    const isChecked = event.checked;
    this.dynamicForm.get(field.code)?.setValue(isChecked);
  }

  saveForm() {
    this.markAllControlsAsTouched(this.dynamicForm);
    if (this.dynamicForm.valid) {
      let payload = this.dynamicForm.value;
      if (payload.plannedETA) {
        const formattedDate = this.datePipe.transform(payload.plannedETA, "dd-MMM-yyyy");
        payload = { ...payload, plannedETA: formattedDate };
      }
      if (this.isEditMode) {
        this.customService.updateCustomObj(this.configName, payload, this.editObjId).subscribe((res: any) => {
          this.showSnackbar('Custom object updated successfully!');
          this.closeOverlay();
          this.getCustomObjList();
        }, (error) => {
          this.showSnackbar('Unable to create custom object');
          this.closeOverlay();
        });


      } else {
        this.customService.createCustomObj(this.configName, payload).subscribe((res) => {
          this.showSnackbar('Custom object created successfully!');
          this.closeOverlay();
          this.getCustomObjList();
        },
          (error) => {
            this.showSnackbar('Unable to create custom object');
            this.closeOverlay();
          })
      }
    }
  }
}
