import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FilterUiService } from 'src/app/services/filter-ui.service';
import { FilterBayService } from 'src/app/services/filter-bay.service';
import { DatePipe } from "@angular/common";
import { FormBuilder } from "@angular/forms";
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";

export const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY", // Parsing input from the user
  },
  display: {
    dateInput: "DD/MM/YYYY", // Display format in the input field
    monthYearLabel: "MMM YYYY", // Format for the month/year label in the calendar
    dateA11yLabel: "DD/MM/YYYY", // Accessibility label for date
    monthYearA11yLabel: "MMMM YYYY", // Accessibility label for month/year
  },
};



@Component({
  selector: "app-material-master-filter-molecule",
  templateUrl: "./material-master-filter-molecule.component.html",
  styleUrls: ["./material-master-filter-molecule.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" }, // Locale for dd/mm/yyyy format
  ],
})
export class MaterialMasterFilterMoleculeComponent
  implements OnInit, OnChanges
{
  filterGroup: FormGroup;
  @Input() isDateFilter: boolean = false;
  @Input() defaultDateRange: { startDate: String; endDate: String } = null;
  @Input() changesDetected: boolean;
  @Input() productTypes: [];
  @Input() analyticsFilter: boolean = false;
  @Output() emitAppliedFilters = new EventEmitter<{}>();
  @Output() closeFiltersOutput = new EventEmitter<boolean>();


  filterDataList;
  endDate = new Date();
  addFilters: any;
  public datePipe = new DatePipe("en-GB");
  dateRangeForm: FormGroup;
  public filteredList = {};
  public openState = {};
  isLoading: boolean = true;

  selectedOptions: { [key: string]: string[] } = {};
  constructor(
    private filterService: FilterUiService,
    private filterBayService: FilterBayService,
    private fb: FormBuilder
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
   
  }
  ngOnInit(): void {
    this.filterBayService.getMaterialMasterFilters.subscribe((data) => {
      this.closeFilter(data);
    });
    this.dateRangeForm = !this.defaultDateRange
      ? new FormGroup({
          startDate: new FormControl(null),
          endDate: new FormControl(null),
        })
      : new FormGroup({
          startDate: new FormControl(this.defaultDateRange.startDate),
          endDate: new FormControl(this.defaultDateRange.endDate),
        });
    const fieldControl = {};
    this.filterService
      .getFilters(this.productTypes, this.analyticsFilter)
      .subscribe(
        (res) => {
          this.filterDataList = res["filterDataList"].filter((filter) => {
            return filter.values && filter.values.length > 0;
          });
          this.filterDataList.map((field) => {
            const control = field.code;
            this.filteredList[field.code] = field.values.slice();
            fieldControl[control] = new FormControl();
          });
        },
        (error) => {
          this.isLoading = false;
        },
        () => {
          this.filterGroup = new FormGroup(fieldControl);
          this.isLoading = false;
        }
      );
  }
  closeFilter(i) {
    if (i === "DateRange") {
      const dateRange = ["startDate", "endDate"];
      dateRange.filter((date) => {
        const control = this.dateRangeForm.get(date);
        control.patchValue(null);
      });
      for (const key in this.addFilters) {
        if (key === i) {
          delete this.addFilters[key];
        }
      }
      this.applyFilters();
    } else {
      const getControlByname = this.filterGroup.get(i);
      if (getControlByname.value != null && getControlByname.value.length > 0) {
        this.filterGroup.get(i).patchValue(null);
        delete this.selectedOptions[i];
      }
      this.applyFilters();
    }
  }
  applyFilters() {
    const buildPayload: any = { filters: {} };
    for (const key in this.filterGroup.value) {
      if (
        this.filterGroup.value[key] &&
        this.filterGroup.value[key].length > 0
      ) {
        buildPayload.filters[key] = this.selectedOptions[key];
      }
    }
    const startDate =
      this.dateRangeForm.get("startDate").value != null
        ? this.datePipe.transform(
            new Date(this.dateRangeForm.get("startDate").value),
            "yyyy-MM-dd"
          )
        : null;
    const endDate =
      this.dateRangeForm.get("endDate").value != null
        ? this.datePipe.transform(
            new Date(this.dateRangeForm.get("endDate").value),
            "yyyy-MM-dd"
          )
        : null;
    if (startDate != null) {
      buildPayload.startDate = startDate;
    }
    if (endDate != null) {
      buildPayload.endDate = endDate;
    }
    if (startDate != null && endDate == null) {
      buildPayload.DateRange = `from ${startDate}`;
    } else if (startDate == null && endDate != null) {
      buildPayload.DateRange = `till ${endDate}`;
    } else if (startDate != null && endDate != null) {
      buildPayload.DateRange = `${startDate} - ${endDate}`;
    }
    this.emitAppliedFilters.emit(buildPayload);
  }
  toggleSelectAll(filterCode: string) {
    // Get the filter group for the given filterCode
    const filterControl = this.filterGroup.get(filterCode);

    // Check if the filterControl exists
    if (filterControl) {
      const allOptionValues: string[] =
        this.filterDataList.find((filter) => filter.code === filterCode)
          ?.values || [];

      // Check if all options are selected, if so, deselect all; otherwise, select all
      if (this.selectedOptions[filterCode]?.length === allOptionValues.length) {
        this.selectedOptions[filterCode] = [];
      } else {
        this.selectedOptions[filterCode] = [...allOptionValues];
      }

      // Update the filter control's value with the selected options
      filterControl.setValue(this.selectedOptions[filterCode]);
    } else {
      console.error(`Filter control for ${filterCode} not found.`);
    }
  }
  // selectSingle(filterCode: string , variable: string) {
  //   console.log("filterCode:", filterCode);
  //   console.log(this.filterGroup , "filterGroup")

  //   // Get the form control for the given filterCode
  //   const filterControl = this.filterGroup.get(filterCode);
  //   console.log("filterControl:", filterControl);

  //   if (!filterControl) {
  //     console.error(`Form control for ${filterCode} is not initialized.`);
  //     return;
  //   }

  //   const currentValue = variable;
  //   console.log("currentValue:", currentValue);

  //   if (!Array.isArray(currentValue)) {
  //     console.error(`The value for ${filterCode} is not an array.`);
  //     return;
  //   }

  //   // Filter out '0' values (used for "Select All")
  //   const selectedValues = currentValue.filter((value) => value !== 0);
  //   console.log("selectedValues:", selectedValues);

  //   // Get the possible values for the filter from filterDataList
  //   const allOptionValues =
  //     this.filterDataList.find((filter) => filter.code === filterCode)
  //       ?.values || [];
  //   console.log("allOptionValues:", allOptionValues);

  //   // If all options are selected, we add '0' to indicate "Select All"
  //   if (selectedValues.length === allOptionValues.length) {
  //     filterControl.setValue([...selectedValues, 0]);
  //   } else {
  //     filterControl.setValue(selectedValues);
  //   }

  //   // Update selected options for the filter
  //   this.selectedOptions[filterCode] = filterControl.value.filter(
  //     (v) => v !== 0
  //   );

  //   console.log("selectedOptions:", this.selectedOptions);
  // }

  selectSingle(filterCode: string, variable: any) {
    // Get the current selected values for the given filterCode
    const currentValues = this.filterGroup.value[filterCode] || [];

    // If the variable is already in the selected values, remove it (unselect)
    if (currentValues.includes(variable)) {
      // Remove the variable from the selection
      const updatedValues = currentValues.filter((v) => v !== variable);
      this.filterGroup.get(filterCode).setValue(updatedValues);
    } else {
      // Otherwise, add the variable to the selection
      const updatedValues = [...currentValues, variable];
      this.filterGroup.get(filterCode).setValue(updatedValues);
    }

    // Filter out the '0' value (used for "Select All")
    const getFilterValues = this.filterGroup.value[filterCode].filter(
      (ignoreZero) => ignoreZero !== 0
    );

    // Get all available option values from the filterDataList
    const allOptionValues =
      this.filterDataList.find((filter) => filter.code === filterCode)
        ?.values || [];

    // If all options are selected, add '0' to indicate "Select All"
    if (getFilterValues.length === allOptionValues.length) {
      this.filterGroup.get(filterCode).setValue([...getFilterValues, 0]);
    } else {
      // Otherwise, remove '0' if it's present (to unselect "Select All")
      this.filterGroup.get(filterCode).setValue(getFilterValues);
    }

    // Update selected options for this filter code, excluding '0'
    this.selectedOptions[filterCode] = getFilterValues;

    // Log the selected options for debugging
    console.log(this.selectedOptions, "selectedsingle");
  }
  clearFilter(filterCode: string): void {
    // Clear the selected options for the given filter code
    this.filterGroup.get(filterCode).setValue([]);
    this.selectedOptions[filterCode] = [];
  }

  closeFilters() {
    this.closeFiltersOutput.emit(true);
  }
}
