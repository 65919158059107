import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { ListingGridService } from 'src/app/services/listing-grid.service';
import { ClickStreamAnalyticsService, EventType } from "src/app/services/clickstream-analytics-service";
import { SalesOrdersService } from 'src/app/services/sales-orders.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-sales-orders-organism',
  templateUrl: './sales-orders-organism.component.html',
  styleUrls: ['./sales-orders-organism.component.scss']
})
export class SalesOrdersOrganismComponent implements OnInit {
  @ViewChild('customerDialogTemplate') customerDialogTemplate!: TemplateRef<any>;
  isCreated: boolean = false;
  selectedCustomer: any;
  searchProductText: any = '';
  loading: boolean = false;
  filteredProductList: any[] = [];
  cartItems: any[] = [];
  showLargeImageId: string = '';
  showProducts: boolean = false;
  cartVisible: boolean = false;
  dialogRef!: MatDialogRef<any>;
  filteredCustomers: any = [];
  searchCustomerText: any = '';
  selectedCartId: any;
  isButtonEnabledMap: { [key: string]: boolean } = {};
  isStockTake: boolean = false;
  constructor(private snack: MatSnackBar, private dialog: MatDialog, private salesOrderService: SalesOrdersService) {}

  ngOnInit(): void {
    this.selectedCartId = null;
    this.selectedCustomer = null;
    this.isCreated = false;
    this.showProducts = false;
    this.cartVisible = false;
    this.fetchCustomers();
  }
  showSnackbar(message: string) {
    this.snack.open(message, 'close', {
      duration: 4000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }
  goBack() {
    if (this.isCreated && !this.cartVisible) {
      this.isCreated = false;
      this.selectedCustomer = null;
    }
    if (this.isCreated && this.cartVisible) {
      this.cartVisible = false;
    }
  }
  openCustomerDialog() {
    this.fetchCustomers();
    this.dialogRef = this.dialog.open(this.customerDialogTemplate, {
      width: '400px',
      maxWidth: '90vw',
      panelClass: 'custom-dialog'
    })
    this.dialogRef.afterClosed().subscribe(selectedCustomer => {
      if (selectedCustomer) {
        this.selectedCustomer = selectedCustomer;
        document.getElementById('customerButton')?.focus();
        this.getProducts();
      }
    });
  }
  selectCustomer(customer: any) {
    if (customer.partnerId) {
      this.salesOrderService.selectCustomer(customer.partnerId).subscribe((res: any) => {
        this.selectedCartId = res.cartId;
        this.dialogRef.close(customer);
        this.searchCustomerText = '';
      }, (error) => {
        this.showSnackbar('Error while selecting customer. Please try again later');
      });
    }

  }
  fetchCustomers() {
    this.salesOrderService.customersList().subscribe((data: any) => {
      this.filteredCustomers = data;
    });
  }
  filterCustomers() {
    if (this.searchCustomerText.trim()) {
      this.salesOrderService.searchCustomer(this.searchCustomerText).subscribe((res: any) => {
        this.filteredCustomers = res;
      }, (error) => {
        this.showSnackbar('Error while searching customer. Please try again later');
      })
    }
  }
  clearSearch() {
    this.searchCustomerText = '';
    this.fetchCustomers();
  }

  changeActiveImage(args: string) {
    this.showLargeImageId = args;
  }

  getcustomerList(searchTerm: string) {
    this.salesOrderService.searchCustomer(searchTerm).subscribe((list: any) => {
      this.filteredCustomers = list;
    })
  }
  createOrder() {
    this.isCreated = true;
    this.showProducts = false;
  }
  stockTake() {
    this.isStockTake = true;
    this.isCreated = true;
    this.showProducts = false;
  }
  getProducts() {
    this.showProducts = true;
    this.loading = true;
    this.salesOrderService.productsList().subscribe((products: any) => {
      this.filteredProductList = products;
      this.loading = false;
    }, (error) => {
      this.showSnackbar('Error while fetching products. Please try again later');
    })
  }
  filterProducts() {
    if (this.searchProductText.trim()) {
      this.salesOrderService.searchProducts(this.searchProductText).subscribe((res: any) => {
        this.filteredProductList = res;
      }, (error) => {
        this.showSnackbar('Error while searching product. Please try again later');
      })
    }
  }
  clearProductSearch() {
    this.searchProductText = '';
    this.getProducts();
  }
  updateStock(product:any){
    const payload = {
      "materialCode": product.code,
      "locationCode": this.selectedCustomer.partnerId,
      "stock": product.orderQty,
  };
    this.salesOrderService.addInventory(payload).subscribe((res:any)=>{
      this.isButtonEnabledMap[product.code] = false;
      this.showSnackbar(res);
    },(error)=>{
      this.showSnackbar('Failed to update')
    })
  }

  addToCart(product: any): void {
    if (product.orderQty > 0 || product.replacementQty > 0) {
      const payload = {
        "cartId": this.selectedCartId,
        "partnerId": this.selectedCustomer.partnerId,
        "materialCode": product.code || product.materialCode,
        "orderQty": product.orderQty,
        "replacementQty": product.replacementQty
      }
      this.salesOrderService.addItemToCart(payload).subscribe((res: any) => {
        this.cartItems = res;
        this.isButtonEnabledMap[product.code] = false;
      }, (error) => {
        this.showSnackbar('Error while adding to cart. Please try again later');
      })
    }
  }
  viewCart() {
    this.salesOrderService.viewCart(this.selectedCartId).subscribe((res: any) => {
      this.cartItems = res;
      this.cartVisible = true;
      if (this.cartItems.length <= 0) {
        this.cartVisible = false;
        this.getProducts();
      }
    }, (error) => {
      this.showSnackbar('Error while fetching cart items. Please try again later');
    })
  }
  onQuantityChange(product: any): void {
    this.isButtonEnabledMap[product.code] = product.orderQty > 0 || product.replacementQty > 0;
  }
  updateItem(product: any) {
    // this.addToCart(product);
    const payload = {
      "cartId": this.selectedCartId,
      "partnerId": this.selectedCustomer.partnerId,
      "materialCode": product.code || product.materialCode,
      "orderQty": product.orderQty,
      "replacementQty": product.replacementQty
    };
    this.salesOrderService.updateItem(payload).subscribe((res: any) => {
      this.cartItems = res;
    }, (error) => {
      this.showSnackbar('Error while adding to cart. Please try again later');
    })
  }
  deleteItem(product: any): void {
    this.salesOrderService.removeCartItem(product.cartId, product.materialCode).subscribe((res: any) => {
      this.showSnackbar(res);
      this.viewCart();
    }, (error) => {
      this.showSnackbar('Error while deleting item. Please try again later');
    })
  }
  checkout() {
    if (this.selectedCartId) {
      this.salesOrderService.placeOrder(this.selectedCartId).subscribe((res: any) => {
        if (res) {
          this.showSnackbar('Order created successfully');
          this.isCreated = false;
          this.showProducts = false;
          this.cartVisible = false;
          this.selectedCartId = null;
        }
      }, (error) => {
        this.showSnackbar('Failed to create order');
      });
    } else {
      this.selectCustomer(this.selectedCustomer);
    }
  }
}
